<template>
  <loading
    :active="isLoadingModel"
    :can-cancel="true"
    color="#ffc107"
    :is-full-page="fullPage"
  ></loading>
  <div class="card">
    <div class="col-12">
      <Dropdown
        style="width: 100%"
        v-model="submissionstatus"
        :options="submissionItems"
        :value="submissionItems.value"
        optionLabel="name"
        placeholder="Select Year"
        v-on:change="get_dashboarddata"
      />
    </div>
  </div>
  <div class="grid">
    <div class="col-12 xl:col-6">
      <div class="card">
        <h5>No. of Courses and Students Registered</h5>
        <hr />
        <Chart type="bar" :data="barData" :options="barOptions" />
        <DataTable
          :value="firstproducts"
          class="p-datatable-sm"
          responsiveLayout="scroll"
          style="font-size: 12px"
          showGridlines :rowHover="true"
        >
          <Column header="Courses">
            <template #body="{ data }">
              <span
                v-if="
                  data.label == '' ||
                  data.label == null ||
                  data.label == undefined
                "
                >-</span
              >
              <span v-else>
                <span v-if="data.label == 'Total'"
                  ><b>{{ data.label }}</b></span
                >
                <span v-else>{{ data.label }} </span>
              </span>
            </template>
          </Column>

          <Column header="Students" style="width: 52px; text-align: right">
            <template #body="{ data }">
              <span
                v-if="
                  data.value == '' ||
                  data.value == null ||
                  data.value == undefined
                "
                >-</span
              >
              <span v-else>
                <span v-if="data.label == 'Total'"
                  ><b>{{ data.value }}</b></span
                >
                <span v-else>{{ data.value }} </span>
              </span>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <div class="col-12 xl:col-6">
      <div class="card">
        <h5>No. of Teachers</h5>
        <hr />
        <Chart type="bar" :data="TbarData" :options="TbarOptions" />
        <DataTable
          :value="Tfirstproducts"
          class="p-datatable-sm"
          responsiveLayout="scroll"
          style="font-size: 12px"
          showGridlines :rowHover="true"
        >
          <Column header="Courses">
            <template #body="{ data }">
              <span
                v-if="
                  data.label == '' ||
                  data.label == null ||
                  data.label == undefined
                "
                >-</span
              >
              <span v-else>
                <span v-if="data.label == 'Total'"
                  ><b>{{ data.label }}</b></span
                >
                <span v-else>{{ data.label }} </span>
              </span>
            </template>
          </Column>
          <Column header="Teachers" style="width: 52px; text-align: right">
            <template #body="{ data }">
              <span
                v-if="
                  data.value == '' ||
                  data.value == null ||
                  data.value == undefined
                "
                >-</span
              >
              <span v-else>
                <span v-if="data.label == 'Total'"
                  ><b>{{ data.value }}</b></span
                >
                <span v-else>{{ data.value }} </span>
              </span>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>

  <!-- -------------------------------------------------------------------------------- -->
  <div class="grid">
    <div class="col-12 xl:col-6">
      <div class="card">
        <h5>Lecture by Teacher</h5>
        <hr />
        <Chart type="pie" :data="chartData" :options="lightOptions" />
        <DataTable
          :value="Lfirstproducts"
          class="p-datatable-sm"
          responsiveLayout="scroll" showGridlines
          style="font-size: 12px" :rowHover="true"
        >
          <Column field="label" header="Teacher's Name" style="text-align:left"></Column>
          <Column field="value" header="No of Lectures" style="text-align:right"></Column>
          <Column field="attended" header="Attended" style="text-align:right"></Column>
          <Column field="no_of_assignment" header="No Of Assignment" style="text-align:right"></Column>
          <Column field="submitted_answer" header="Submitted Answer" style="text-align:right"></Column>
        </DataTable>
      </div>
    </div>

    <div class="col-12 xl:col-6">
      <div class="card">
        <h5>Today's Assignment upload</h5>
        <hr />
        <Dropdown
          style="width: 100%"
          v-model="submissionstatus2"
          :options="submissionItems2"
          :value="submissionItems2.value"
          optionLabel="name"
          placeholder="Select Year"
          :rowHover="true"
          v-on:change="get_todaysassignmentupload" 
        />
        <DataTable
          ref="dt2"
          :value="TAU_product"
          class="p-datatable-sm"
          :rows="10"
          responsiveLayout="scroll"
          style="font-size: 12px"
          :paginator="true"
          dataKey="id" showGridlines
          :rowHover="true"
        >
          <Column field="teacherName" header="Teacher Name"></Column>
          <Column field="topic" header="Topic"></Column>
        </DataTable>
      </div>
    </div>
  </div>

  <!-- -------------------------------------------------------------------------------- -->
  <div class="grid">
    <div class="col-12 xl:col-6">
      <div class="card">
        <DataTable
          ref="dt1"
          :value="A_product"
          class="p-datatable-sm"
          :rows="10"
          responsiveLayout="scroll"
          style="font-size: 12px"
          :paginator="true"
          dataKey="id"
          :rowHover="true"
          showGridlines
        >
          <template #header>
            <div
              class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
            >
              <h5 class="m-0" v-if="A_product">
                Leader Board (top 10) - {{ A_product[0].poll_name }} 

              </h5>
              <Button
          icon="pi pi-fw pi-download"
          class="p-button-rounded p-button-outlined"
          @click="exportCSV1()"
        />
            </div>
          </template>
          <Column field="student_name" header="Student Name" style="width:60%">
             <template #body="{ data }">
             <span
                v-if="
                  data.student_name == '' ||
                  data.student_name == null ||
                  data.student_name == undefined
                "
                >-</span
              >
              <span v-else>{{ data.student_name }}</span>
             </template>
          
          </Column>
          <Column field="correct" header="Correct" style="text-align:right;">
            <template #body="{ data }">
            <span
                v-if="
                  data.correct == '' ||
                  data.correct == null ||
                  data.correct == undefined
                "
                >-</span
              ><span v-else>{{ data.correct }}</span>
             </template>
          </Column>
          <Column field="wrong" header="Wrong" style="text-align:right;">
            <template #body="{ data }">
            <span
                v-if="
                  data.wrong == '' ||
                  data.wrong == null ||
                  data.wrong == undefined
                "
                >-</span
              ><span v-else>{{ data.wrong }}</span>
             </template>
          </Column>
          <Column field="answer_time" header="Answer Time" style="text-align:right;">
            <template #body="{ data }">
            <span
                v-if="
                  data.answer_time == '' ||
                  data.answer_time == null ||
                  data.answer_time == undefined ||
                   data.answer_time == ':'
                "
                >-</span
              ><span v-else>{{ data.answer_time }}</span>
             </template>
          </Column>
        </DataTable>
        
      </div>
    </div>

    <div class="col-12 xl:col-6">
      <div class="card">
        <DataTable
          ref="dt2"
          :value="B_product"
          class="p-datatable-sm"
          :rows="10"
          responsiveLayout="scroll"
          style="font-size: 12px"
          :paginator="true"
          dataKey="id"
          :rowHover="true"
          showGridlines
        >
          <template #header>
            <div
              class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
            >
              <h5 class="m-0" v-if="B_product">
                Leader Board (top 10) - {{ B_product[0].poll_name }}
              </h5>
              <Button
          icon="pi pi-fw pi-download"
          class="p-button-rounded p-button-outlined"
          @click="exportCSV2()"
        />
            </div>
          </template>
          <Column field="student_name" header="Student Name" style="width:60%">
             <template #body="{ data }">
             <span
                v-if="
                  data.student_name == '' ||
                  data.student_name == null ||
                  data.student_name == undefined
                "
                >-</span
              >
              <span v-else>{{ data.student_name }}</span>
             </template>
          
          </Column>
          <Column field="correct" header="Correct" style="text-align:right;">
            <template #body="{ data }">
            <span
                v-if="
                  data.correct == '' ||
                  data.correct == null ||
                  data.correct == undefined
                "
                >-</span
              ><span v-else>{{ data.correct }}</span>
             </template>
          </Column>
          <Column field="wrong" header="Wrong" style="text-align:right;">
            <template #body="{ data }">
            <span
                v-if="
                  data.wrong == '' ||
                  data.wrong == null ||
                  data.wrong == undefined
                "
                >-</span
              ><span v-else>{{ data.wrong }}</span>
             </template>
          </Column>
          <Column field="answer_time" header="Answer Time" style="text-align:right;">
            <template #body="{ data }">
            <span
                v-if="
                  data.answer_time == '' ||
                  data.answer_time == null ||
                  data.answer_time == undefined ||
                   data.answer_time == ':'
                "
                >-</span
              ><span v-else>{{ data.answer_time }}</span>
             </template>
          </Column>
        </DataTable>
        <!-- <Button
          icon="pi pi-fw pi-download"
          class="p-button-rounded p-button-outlined"
          @click="exportCSV2()"
        /> -->
      </div>
    </div>
  </div>

  <!-- -------------------------------------------------------------------------------- -->
  <div class="grid">
    <div class="col-12 xl:col-6">
      <div class="card">
        <DataTable
          ref="dt3"
          :value="C_product"
          class="p-datatable-sm"
          :rows="10"
          responsiveLayout="scroll"
          style="font-size: 12px"
          :paginator="true"
          dataKey="id"
          :rowHover="true" showGridlines
        >
          <template #header>
            <div
              class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
            >
              <h5 class="m-0" v-if="C_product">
                Leader Board (top 10) - {{ C_product[0].poll_name }}
              </h5>
              <Button
          icon="pi pi-fw pi-download"
          class="p-button-rounded p-button-outlined"
          @click="exportCSV3()"
        />
            </div>
          </template>
         <Column field="student_name" header="Student Name" style="width:60%">
             <template #body="{ data }">
             <span
                v-if="
                  data.student_name == '' ||
                  data.student_name == null ||
                  data.student_name == undefined
                "
                >-</span
              >
              <span v-else>{{ data.student_name }}</span>
             </template>
          
          </Column>
          <Column field="correct" header="Correct" style="text-align:right;">
            <template #body="{ data }">
            <span
                v-if="
                  data.correct == '' ||
                  data.correct == null ||
                  data.correct == undefined
                "
                >-</span
              ><span v-else>{{ data.correct }}</span>
             </template>
          </Column>
          <Column field="wrong" header="Wrong" style="text-align:right;">
            <template #body="{ data }">
            <span
                v-if="
                  data.wrong == '' ||
                  data.wrong == null ||
                  data.wrong == undefined
                "
                >-</span
              ><span v-else>{{ data.wrong }}</span>
             </template>
          </Column>
          <Column field="answer_time" header="Answer Time" style="text-align:right;">
            <template #body="{ data }">
            <span
                v-if="
                  data.answer_time == '' ||
                  data.answer_time == null ||
                  data.answer_time == undefined ||
                   data.answer_time == ':'
                "
                >-</span
              ><span v-else>{{ data.answer_time }}</span>
             </template>
          </Column>
        </DataTable>
        <!-- <Button
          icon="pi pi-fw pi-download"
          class="p-button-rounded p-button-outlined"
          @click="exportCSV3()"
        /> -->
      </div>
    </div>

    <div class="col-12 xl:col-6">
      <div class="card">
        <DataTable
          ref="dt4"
          :value="D_product"
          class="p-datatable-sm"
          :rows="10"
          responsiveLayout="scroll"
          style="font-size: 12px"
          :paginator="true"
          dataKey="id"
          :rowHover="true" showGridlines
        >
          <template #header>
            <div
              class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
            >
              <h5 class="m-0" v-if="D_product">
                Leader Board (top 10) - {{ D_product[0].poll_name }}
              </h5>
              <Button
          icon="pi pi-fw pi-download"
          class="p-button-rounded p-button-outlined"
          @click="exportCSV4()"
        />
            </div>
          </template>
          <Column field="student_name" header="Student Name" style="width:60%">
             <template #body="{ data }">
             <span
                v-if="
                  data.student_name == '' ||
                  data.student_name == null ||
                  data.student_name == undefined
                "
                >-</span
              >
              <span v-else>{{ data.student_name }}</span>
             </template>
          
          </Column>
          <Column field="correct" header="Correct" style="text-align:right;">
            <template #body="{ data }">
            <span
                v-if="
                  data.correct == '' ||
                  data.correct == null ||
                  data.correct == undefined
                "
                >-</span
              ><span v-else>{{ data.correct }}</span>
             </template>
          </Column>
          <Column field="wrong" header="Wrong" style="text-align:right;">
            <template #body="{ data }">
            <span
                v-if="
                  data.wrong == '' ||
                  data.wrong == null ||
                  data.wrong == undefined
                "
                >-</span
              ><span v-else>{{ data.wrong }}</span>
             </template>
          </Column>
          <Column field="answer_time" header="Answer Time" style="text-align:right;">
            <template #body="{ data }">
            <span
                v-if="
                  data.answer_time == '' ||
                  data.answer_time == null ||
                  data.answer_time == undefined ||
                   data.answer_time == ':'
                "
                >-</span
              ><span v-else>{{ data.answer_time }}</span>
             </template>
          </Column>
        </DataTable>
        <!-- <Button
          icon="pi pi-fw pi-download"
          class="p-button-rounded p-button-outlined"
          @click="exportCSV4()"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from "@/AppEventBus";
import ProductService from "../service/ProductService";
import apis from "@/apis";
import { ref } from "vue";
export default {
  data() {
    return {
      isLoadingModel: false,
      //------------------------------------------------------------------------
      barDataY: [],
      barData: {
        labels: [],
        datasets: [
          {
            label: "Students",
            backgroundColor: "#2f4860",
            data: [],
          },
        ],
      },
      barOptions: null,
      firstproducts: null,
      //------------------------------------------------------------------------
      TbarDataY: [],
      TbarData: {
        labels: [],
        datasets: [
          {
            label: "Teachers",
            backgroundColor: "#2f4860",
            data: [],
          },
        ],
      },
      TbarOptions: null,
      Tfirstproducts: null,
      //-------------------------------------------------------------------------
      chartDataY: [],
      chartData: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
            hoverBackgroundColor: [],
          },
        ],
      },
      lightOptions: {
        plugins: {
          legend: {
            labels: {
              color: "#495057",
            },
          },
        },
      },
      Lfirstproducts: null,

      //------------------------------------------------------------------------
      productService: null,
      themeChangeListener: null,
      submissionItems: [],
      submissionstatus: { name: "2022-23", value: 2022 },
      submissionItems2: [],
      submissionstatus2: { name: "", value: "" },

      A_product: null,
      B_product: null,
      C_product: null,
      D_product: null,
      TAU_product: null,
    };
  },

  methods: {
    exportCSV1() {
      this.$refs.dt1.exportCSV();
    },
    exportCSV2() {
      this.$refs.dt2.exportCSV();
    },
    exportCSV3() {
      this.$refs.dt3.exportCSV();
    },
    exportCSV4() {
      this.$refs.dt4.exportCSV();
    },
    get_submission_dropdown: function () {
      var data = {
        Limit: 100,
        page_no: this.page_no,
        count: false,
      };
      this.loading = true;
      var promise = apis.yearlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        var info = response.data;
        if (info) {
          this.submissionItems = [];
          for (var i = 0; i < info.length; i++) {
            this.submissionItems.push({
              name: info[i].exaM_YEAR,
              value: info[i].enrollmenT_YEAR,
            });
          }
        }
      });
    },
    get_cource_dropdown: function () {
      var data = {
        Limit: 100,
        page_no: this.page_no,
        count: false,
      };
      this.loading = true;
      var promise = apis.batchlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        var info = response.data;
        if (info) {
          this.submissionItems2 = [];
          for (var i = 0; i < info.length; i++) {
            this.submissionItems2.push({
              name: info[i].batch,
              value: info[i].mjmB_ID,
            });
          }
        }
      });
    },
    get_dashboarddataleaderboards() {
      this.isLoadingModel = true;
      var data = {
        year: this.submissionstatus.value,
      };
      var promise = apis.dashboarddataleaderboards(data);
      promise.then((response) => {
        this.isLoadingModel = false;
        if (response.data.admindashboardleaderboard1) {
          this.A_product = response.data.admindashboardleaderboard1;
        }
        if (response.data.admindashboardleaderboard2) {
          this.B_product = response.data.admindashboardleaderboard2;
        }
        if (response.data.admindashboardleaderboard3) {
          this.C_product = response.data.admindashboardleaderboard3;
        }
        if (response.data.admindashboardleaderboard4) {
          this.D_product = response.data.admindashboardleaderboard4;
        }
      });
    },
    get_todaysassignmentupload() {
      var data = {
        Batch: this.submissionstatus2.value,
      };
      var promise = apis.todaysassignmentupload(data);
      promise.then((response) => {
        if (response.data) {
          this.TAU_product = response.data;
        }
      });
    },
    get_dashboarddata() {
      this.isLoadingModel = true;
      var data = {
        year: this.submissionstatus.value,
      };
      var promise = apis.dashboarddata(data);
      promise.then((response) => {
        this.isLoadingModel = false;
        //------------------------------No. of Courses and Students Registered----------------------------------------------------------
        if (response.data.dashboardstudentregreports) {
          this.barData.labels = [];
          this.barData.datasets[0].data = [];
          this.barDataY = [];

          this.firstproducts = response.data.dashboardstudentregreports; //table
          var sum = 0;

          for (
            var i = 0;
            i < response.data.dashboardstudentregreports.length;
            i++
          ) {
            sum = sum + response.data.dashboardstudentregreports[i]["value"];
            //get total no of students

            this.barData.labels.push(
              response.data.dashboardstudentregreports[i].label
            );

            this.barDataY.push(
              response.data.dashboardstudentregreports[i]["value"]
            );
          }

          this.firstproducts.push({ label: "Total", value: sum });
          this.barData.datasets[0].data = this.barDataY;
        }
        //------------------------------------------teacher-------------------------------------------------------------------------------------

        if (response.data.dashboardteacherreports) {
          this.TbarData.labels = [];
          this.TbarData.datasets[0].data = [];
          this.TbarDataY = [];

          this.Tfirstproducts = response.data.dashboardteacherreports; //table
          var sum2 = 0;
          for (
            var A = 0;
            A < response.data.dashboardteacherreports.length;
            A++
          ) {
            sum2 = sum2 + response.data.dashboardteacherreports[A]["value"];

            this.TbarData.labels.push(
              response.data.dashboardteacherreports[A].label
            );

            this.TbarDataY.push(
              response.data.dashboardteacherreports[A]["value"]
            );
          }
          this.Tfirstproducts.push({ label: "Total", value: sum2 });
          this.TbarData.datasets[0].data = this.TbarDataY;
        }

        //------------------------------------------------------lecture by teacher-------------------------------------------------------------------------
        if (response.data.lecbyteacherregreports) {
          this.chartData.labels = [];
          this.chartData.datasets[0].data = [];
          this.chartDataY = [];
          var BC = [];

          this.Lfirstproducts = response.data.lecbyteacherregreports; //table

          for (
            var b = 0;
            b < response.data.lecbyteacherregreports.length;
            b++
          ) {
            this.chartData.labels.push(
              response.data.lecbyteacherregreports[b].label
            );

            this.chartDataY.push(
              response.data.lecbyteacherregreports[b]["value"]
            );

            BC.push("#" + Math.floor(Math.random() * 16777215).toString(16));
          }

          this.chartData.datasets[0].data = this.chartDataY;
          this.chartData.datasets[0].backgroundColor = BC;
          this.chartData.datasets[0].hoverBackgroundColor = BC;
        }

        //-------------------------------------------------------------------------------------------------------------------------------
      });

      this.get_dashboarddataleaderboards();
    },
    isDarkTheme() {
      return this.$appState.darkTheme === true;
    },
    applyLightTheme() {
      this.barOptions = {
        plugins: {
          legend: {
            labels: {
              color: "#495057",
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
          y: {
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
        },
      };
      //--------------------------------------------------------------------------------------
      this.TbarOptions = {
        plugins: {
          legend: {
            labels: {
              color: "#495057",
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
          y: {
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
        },
      };
    },
  },
  watch: {},
  created() {
    this.productService = new ProductService();
    this.get_submission_dropdown();
    this.get_cource_dropdown();
    this.submissionstatus = { name: "2022-23", value: 2022 };
    this.get_dashboarddata();
    this.get_dashboarddataleaderboards();
  },
  mounted() {
    this.productService
      .getProductsSmall()
      .then((data) => (this.products = data));

    this.themeChangeListener = (event) => {
      if (event.dark) this.applyDarkTheme();
      else this.applyLightTheme();
    };
    EventBus.on("change-theme", this.themeChangeListener);

    if (this.isDarkTheme()) {
      this.applyDarkTheme();
    } else {
      this.applyLightTheme();
    }
  },
};
</script>

<style></style>
